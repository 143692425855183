import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { isEmpty, sortBy } from 'lodash';
import { RequiredFieldIndicator, SelectDropdown } from '@gsa/afp-component-library';
import { CREATE_WALLET_BASIC_FIELDS } from './consts';
import { getCurrentFiscalYear } from '../../../utilities/dateUtils';
import { useWalletDetails } from '../wallet-details-provider';
import { ENTITY, ROLE_OP } from '../../../components/role-permission/role-permission';

const getAgencyOrBureauById = (code, list) => {
  if (!code || !list || list.length === 0) {
    return '-';
  }
  return list.find((agency) => agency.id === code)?.name;
};

const WalletBasicInfoFormFields = () => {
  const { agencies, getBureausByPermission, bureaus, wallet, boacOfferingAssociation } = useWalletDetails();
  const fiscalYear = getCurrentFiscalYear();

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  const watchAgency = watch(CREATE_WALLET_BASIC_FIELDS.agency.name);

  useEffect(() => {
    if (watchAgency || wallet?.boacModel?.bureauCode) {
      getBureausByPermission({
        variables: {
          agencyCode: watchAgency || wallet?.boacModel?.agencyCode,
          operation: ROLE_OP.CREATE,
          subject: ENTITY.WALLET,
        },
      });
    }
  }, [watchAgency, wallet?.boacModel?.bureauCode]);

  useEffect(() => {
    if (bureaus.length && !wallet) {
      setValue(CREATE_WALLET_BASIC_FIELDS.bureau.name, '');
    }
  }, [bureaus]);

  if (!isEmpty(boacOfferingAssociation)) {
    return (
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.required && <RequiredFieldIndicator />}
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip && CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip}
          </div>
          {boacOfferingAssociation[CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.agency.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.agency.required && <RequiredFieldIndicator />}
          </div>
          {getAgencyOrBureauById(boacOfferingAssociation[CREATE_WALLET_BASIC_FIELDS.agency.name], agencies)}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.bureau.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.bureau.required && <RequiredFieldIndicator />}
          </div>
          {getAgencyOrBureauById(boacOfferingAssociation[CREATE_WALLET_BASIC_FIELDS.bureau.name], bureaus)}
        </div>
      </div>
    );
  }

  if (wallet) {
    return (
      <div className="grid-row grid-gap">
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.required && <RequiredFieldIndicator />}
            {CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip && CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip}
          </div>
          {wallet[CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.agency.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.agency.required && <RequiredFieldIndicator />}
          </div>
          {getAgencyOrBureauById(wallet.boacModel.agencyCode, agencies)}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_BASIC_FIELDS.bureau.label}{' '}
            {CREATE_WALLET_BASIC_FIELDS.bureau.required && <RequiredFieldIndicator />}
          </div>
          {getAgencyOrBureauById(wallet.boacModel.bureauCode, bureaus)}
        </div>
      </div>
    );
  }

  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-3">
        <Controller
          name={CREATE_WALLET_BASIC_FIELDS.fiscalYear.name}
          control={control}
          render={({ value, onChange, onBlur }) => {
            return (
              <SelectDropdown
                data-testid={`wallet-create-${CREATE_WALLET_BASIC_FIELDS.fiscalYear.name}`}
                label={
                  <span className="text-bold">
                    {CREATE_WALLET_BASIC_FIELDS.fiscalYear.label}{' '}
                    {CREATE_WALLET_BASIC_FIELDS.fiscalYear.required && <RequiredFieldIndicator />}
                    {CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip && CREATE_WALLET_BASIC_FIELDS.fiscalYear.toolTip}
                  </span>
                }
                name={CREATE_WALLET_BASIC_FIELDS.fiscalYear.name}
                id={CREATE_WALLET_BASIC_FIELDS.fiscalYear.id}
                options={[
                  { value: `${fiscalYear}`, label: fiscalYear },
                  { value: `${fiscalYear + 1}`, label: fiscalYear + 1 },
                ]}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(CREATE_WALLET_BASIC_FIELDS.fiscalYear.name, e.target.value);
                }}
                onBlur={onBlur}
                errorMessage={
                  errors && errors[CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]
                    ? errors[CREATE_WALLET_BASIC_FIELDS.fiscalYear.name].message
                    : null
                }
                aria-invalid={errors && errors[CREATE_WALLET_BASIC_FIELDS.fiscalYear.name] ? 'true' : 'false'}
              />
            );
          }}
        />
      </div>
      <div className="grid-col-3">
        <Controller
          name={CREATE_WALLET_BASIC_FIELDS.agency.name}
          control={control}
          render={({ value, onChange, onBlur }) => {
            return (
              <SelectDropdown
                data-testid={`wallet-create-${CREATE_WALLET_BASIC_FIELDS.agency.name}`}
                label={
                  <span className="text-bold">
                    {CREATE_WALLET_BASIC_FIELDS.agency.label}{' '}
                    {CREATE_WALLET_BASIC_FIELDS.agency.required && <RequiredFieldIndicator />}
                    {CREATE_WALLET_BASIC_FIELDS.agency.toolTip && CREATE_WALLET_BASIC_FIELDS.agency.toolTip}
                  </span>
                }
                name={CREATE_WALLET_BASIC_FIELDS.agency.name}
                id={CREATE_WALLET_BASIC_FIELDS.agency.id}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(CREATE_WALLET_BASIC_FIELDS.agency.name, e.target.value);
                }}
                onBlur={onBlur}
                options={[
                  { value: '', label: '- Select agency -' },
                  ...sortBy(agencies, 'id').map((agency) => {
                    return { value: agency.id, label: `${agency.id} - ${agency.name}` };
                  }),
                ]}
                errorMessage={
                  errors && errors[CREATE_WALLET_BASIC_FIELDS.agency.name]
                    ? errors[CREATE_WALLET_BASIC_FIELDS.agency.name].message
                    : null
                }
                aria-invalid={errors && errors[CREATE_WALLET_BASIC_FIELDS.agency.name] ? 'true' : 'false'}
              />
            );
          }}
        />
      </div>
      <div className="grid-col-3">
        <Controller
          name={CREATE_WALLET_BASIC_FIELDS.bureau.name}
          control={control}
          render={({ value, onChange, onBlur }) => {
            return (
              <SelectDropdown
                data-testid={`wallet-create-${CREATE_WALLET_BASIC_FIELDS.bureau.name}`}
                label={
                  <span className="text-bold">
                    {CREATE_WALLET_BASIC_FIELDS.bureau.label}{' '}
                    {CREATE_WALLET_BASIC_FIELDS.bureau.required && <RequiredFieldIndicator />}
                  </span>
                }
                name={CREATE_WALLET_BASIC_FIELDS.bureau.name}
                id={CREATE_WALLET_BASIC_FIELDS.bureau.id}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(CREATE_WALLET_BASIC_FIELDS.bureau.name, e.target.value);
                }}
                onBlur={onBlur}
                options={[
                  { value: '', label: '- Select bureau -' },
                  ...sortBy(bureaus, 'id').map((bureau) => {
                    return { value: bureau.id, label: `${bureau.id} - ${bureau.name}` };
                  }),
                ]}
                errorMessage={
                  errors && errors[CREATE_WALLET_BASIC_FIELDS.bureau.name]
                    ? errors[CREATE_WALLET_BASIC_FIELDS.bureau.name].message
                    : null
                }
                aria-invalid={errors && errors[CREATE_WALLET_BASIC_FIELDS.bureau.name] ? 'true' : 'false'}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default WalletBasicInfoFormFields;

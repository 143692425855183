import React, { useState, useEffect } from 'react';
import { SelectDropdown, TextInput, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { useWalletDetails } from '../wallet-details-provider';
import { generateWalletTasFields, generateWalletIpacFields, CREATE_WALLET_BASIC_FIELDS, FormTypes, AGENCY_467, AID_FOR_AGENCY_467 } from './consts';

const tasCardClasses = classnames(
  'padding-2 bg-blue-5 border-2px border-primary-lighter title-s text-bold radius-md margin-x-05 margin-bottom-1 display-inline-block',
);

const WalletTasFormFields = () => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
    watch,
  } = useFormContext();

  const { wallet, boacOfferingAssociation, createdWallet } = useWalletDetails();
  
  const [createWalletTasFields, setCreateWalletTasFields] = useState(generateWalletTasFields());
  const CREATE_WALLET_IPAC_FIELDS = generateWalletIpacFields();

  const buildOptionList = (fieldName) => {
    switch (fieldName) {
      case createWalletTasFields.availabilityType.name:
        return [];
      default:
        return [];
    }
  };

  const watchAgency = watch(CREATE_WALLET_BASIC_FIELDS?.agency?.name);
  const watchBpoa = watch(createWalletTasFields?.beginningPeriodOfAvailability?.name);
  const watchEpoa = watch(createWalletTasFields?.endingPeriodOfAvailability?.name);
  const watchAvailabilityType = watch(createWalletTasFields?.availabilityType?.name);
  const watchSubLevelPrefix = watch(createWalletTasFields?.subLevelPrefix?.name);
  const watchAllocationTransferAgency = watch(createWalletTasFields?.allocationTransferAgency?.name);
  const watchAgencyIdentifier = watch(createWalletTasFields?.agencyIdentifier?.name);
  const watchMainAccount = watch(createWalletTasFields?.mainAccount?.name);
  const watchSubAccount = watch(createWalletTasFields?.subAccount?.name);

  useEffect(() => {
    if (watchAgency) {
      if(watchAgency === AGENCY_467){
        setValue(createWalletTasFields.agencyIdentifier.name, AID_FOR_AGENCY_467);
      }
      else{
        setValue(createWalletTasFields.agencyIdentifier.name, wallet ? wallet.agencyIdentifier : watchAgency);
      }
      const newFormSchema = generateWalletTasFields(getValues(), createdWallet);
      setCreateWalletTasFields(newFormSchema);
    }
  }, [watchAgency]);

  useEffect(() => {
    if (watchAvailabilityType) {
      setValue(createWalletTasFields.beginningPeriodOfAvailability.name, 0);
      setValue(createWalletTasFields.endingPeriodOfAvailability.name, 0);
    } else if (watchBpoa || watchEpoa) {
      setValue(createWalletTasFields.availabilityType.name, '');
    }
    setCreateWalletTasFields(generateWalletTasFields(getValues(), createdWallet));
  }, [watchBpoa, watchEpoa, watchAvailabilityType]);

  useEffect(() => {
    if (createdWallet) {
      const newFormSchema = generateWalletTasFields(getValues(), true);
      setCreateWalletTasFields(newFormSchema);
    }
  }, [createdWallet]);

  useEffect(() => {
    if (wallet) {
      // populate form fields for edit
      reset({
        [createWalletTasFields?.agencyIdentifier.name]: wallet?.agencyIdentifier,
        [createWalletTasFields?.availabilityType.name]: wallet?.availabilityType,
        [createWalletTasFields?.beginningPeriodOfAvailability.name]: wallet?.beginningPeriodOfAvailability,
        [createWalletTasFields?.endingPeriodOfAvailability.name]: wallet?.endingPeriodOfAvailability,
        [createWalletTasFields?.mainAccount.name]: wallet?.mainAccount || '',
        [createWalletTasFields?.subAccount.name]: wallet?.subAccount || '',
        [createWalletTasFields?.subLevelPrefix.name]: wallet?.subLevelPrefix || '',
        [createWalletTasFields?.allocationTransferAgency.name]: wallet?.allocationTransferAgency || '',
        [CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]: wallet?.fiscalYear,
        [CREATE_WALLET_BASIC_FIELDS?.agency.name]: wallet?.boacModel?.agencyCode,
        [CREATE_WALLET_BASIC_FIELDS?.bureau.name]: wallet?.boacModel?.bureauCode,
        [CREATE_WALLET_IPAC_FIELDS?.accountInformation.name]: wallet?.accountInformation || '',
        [CREATE_WALLET_IPAC_FIELDS?.bcnObanAsn.name]: wallet?.bcnObanAsn || '',
        [CREATE_WALLET_IPAC_FIELDS?.fiscalStation.name]: wallet?.fiscalStation || '',
        [CREATE_WALLET_IPAC_FIELDS?.limit.name]: wallet?.limit || '',
        [CREATE_WALLET_IPAC_FIELDS?.standardDocumentNumber.name]: wallet?.standardDocumentNumber || '',
      });
    }
  }, [wallet]);

  const isEditMode = !isEmpty(wallet);

  return (
    <div className="grid-row grid-gap">
      <div className="tablet:grid-col-7">
        <div className="grid-row grid-gap">
          {Object.values(createWalletTasFields).map((tasField) => {
            const optionList = tasField?.options || buildOptionList(tasField?.name);
            if (tasField?.readOnly) {
              return (
                <div className="grid-col-6">
                  <div className="text-bold margin-top-2">
                    {tasField?.label} {tasField?.required && <RequiredFieldIndicator />}
                  </div>
                  {boacOfferingAssociation[tasField?.name] || <>&ndash;</>}
                </div>
              );
            }
            return (
              <>
                <div className="grid-col-6">
                  <Controller
                    name={tasField?.name}
                    control={control}
                    render={({ value, onChange, onBlur }) => {
                      return (
                        <div key={tasField.name}>
                          {tasField?.type === FormTypes.select ? (
                            <SelectDropdown
                              data-testid={`wallet-create-${tasField?.name}`}
                              label={
                                <span className="text-bold">
                                  {tasField?.label} {tasField.required && <RequiredFieldIndicator />}
                                  {tasField?.toolTip && tasField.toolTip}
                                </span>
                              }
                              name={tasField?.name}
                              id={tasField?.id}
                              disabled={tasField?.disabled}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setValue(tasField?.name, e.target.value);
                              }}
                              onBlur={onBlur}
                              options={optionList}
                              errorMessage={
                                errors && errors[tasField?.name] && !tasField?.disabled
                                  ? errors[tasField?.name].message
                                  : null
                              }
                              aria-invalid={errors && errors[tasField?.name] && !tasField?.disabled ? 'true' : 'false'}
                            />
                          ) : (
                            <TextInput
                              data-testid={`wallet-create-${tasField?.name}`}
                              type="text"
                              name={tasField?.name}
                              id={tasField.id}
                              disabled={tasField?.disabled}
                              value={value}
                              maxLength={tasField?.maxLength}
                              onChange={(e) => {
                                onChange(e);
                                setValue(tasField?.name, e.target.value);
                              }}
                              onBlur={onBlur}
                              label={
                                <span className="text-bold">
                                  {tasField?.label} {tasField?.required && <RequiredFieldIndicator />}
                                  {tasField?.toolTip && tasField?.toolTip}
                                </span>
                              }
                              errorMessage={
                                errors && errors[tasField?.name] && !tasField?.disabled
                                  ? errors[tasField?.name].message
                                  : null
                              }
                              aria-invalid={errors && errors[tasField?.name] && !tasField?.disabled ? 'true' : 'false'}
                            />
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              </>
            );
          })}
          {isEditMode && (
            <>
              <div className="tablet:grid-col-6">
                <div className="text-bold margin-top-3">Last updated date</div>
                {moment(wallet?.updatedAt).format('MM/DD/YYYY . h:mm a') || '\u2014'}
              </div>
              <div className="tablet:grid-col-6">
                <div className="text-bold margin-top-3">Last updated by</div>
                {wallet?.updatedByUser?.fullName || wallet?.createdByUser?.fullName || '\u2014'}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="tablet:grid-col-5 display-flex flex-justify-start">
        <div className="display-block">
          <h3 className="title-s-caps">TAS:</h3>
          {(watchSubLevelPrefix || boacOfferingAssociation?.subLevelPrefix) && (
            <span className={tasCardClasses}>{watchSubLevelPrefix || boacOfferingAssociation?.subLevelPrefix}</span>
          )}
          {(watchAllocationTransferAgency || boacOfferingAssociation?.allocationTransferAgency) && (
            <span className={tasCardClasses}>
              {watchAllocationTransferAgency || boacOfferingAssociation?.allocationTransferAgency}
            </span>
          )}
          {(watchAgencyIdentifier || boacOfferingAssociation?.agencyIdentifier) && (
            <span className={tasCardClasses}>{watchAgencyIdentifier || boacOfferingAssociation?.agencyIdentifier}</span>
          )}
          {((watchBpoa !== 0 && watchBpoa !== '0') || boacOfferingAssociation?.beginningPeriodOfAvailability) && (
            <span className={tasCardClasses}>
              {watchBpoa || boacOfferingAssociation?.beginningPeriodOfAvailability}
            </span>
          )}
          {((watchEpoa !== 0 && watchEpoa !== '0') || boacOfferingAssociation?.endingPeriodOfAvailability) && (
            <span className={tasCardClasses}>{watchEpoa || boacOfferingAssociation?.endingPeriodOfAvailability}</span>
          )}
          {(watchAvailabilityType || boacOfferingAssociation?.availabilityType) && (
            <span className={tasCardClasses}>{watchAvailabilityType || boacOfferingAssociation?.availabilityType}</span>
          )}
          {(watchMainAccount || boacOfferingAssociation?.mainAccount) && (
            <span className={tasCardClasses}>{watchMainAccount || boacOfferingAssociation?.mainAccount}</span>
          )}
          {(watchSubAccount || boacOfferingAssociation?.subAccount) && (
            <span className={tasCardClasses}>{watchSubAccount || boacOfferingAssociation?.subAccount}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletTasFormFields;
